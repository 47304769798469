define(['santa-components', 'lodash', 'componentsCore'], function (santaComponents, _, componentsCore) {
    'use strict';

    function getLinkAttributes(linkProps) {
        let attributes;
        if (_.isEmpty(linkProps)) {
            attributes = {parentConst: santaComponents.utils.createReactElement.bind(null, 'div')};
        } else {
            attributes = linkProps;
            attributes.style = {cursor: 'pointer'};
        }

        return attributes;
    }

    function getStyleHtml(styleArray, styleId, id) {
        return _.reduce(styleArray, (res, styleLine) => `${res} .${styleId}_${id} ${styleLine}`, '');
    }

    return {
        displayName: 'VectorImage',
        mixins: [componentsCore.mixins.skinBasedComp],
        propTypes: {
            id: santaComponents.santaTypesDefinitions.Component.id,
            styleId: santaComponents.santaTypesDefinitions.Component.styleId,
            style: santaComponents.santaTypesDefinitions.Component.style,
            svgId: santaComponents.santaTypesDefinitions.VectorImage.svgId,
            link: santaComponents.santaTypesDefinitions.VectorImage.link,
            compProp: santaComponents.santaTypesDefinitions.Component.compProp,
            theme: santaComponents.santaTypesDefinitions.Component.theme,
            flipTransformStyle: santaComponents.santaTypesDefinitions.VectorImage.flipTransformStyle,
            svgString: santaComponents.santaTypesDefinitions.VectorImage.svgString,
            svgInfo: santaComponents.santaTypesDefinitions.VectorImage.svgInfo,
            svgType: santaComponents.santaTypesDefinitions.VectorImage.svgType,
            overrideColorsAsCss: santaComponents.santaTypesDefinitions.VectorImage.overrideColorsAsCss,
            shapeStyle: santaComponents.santaTypesDefinitions.VectorImage.shapeStyle,
            preserveViewBox: santaComponents.santaTypesDefinitions.VectorImage.preserveViewBox,
            shouldScaleStroke: santaComponents.santaTypesDefinitions.VectorImage.shouldScaleStroke
        },

        getSvgContent(strokeWidth) {
            return {
                style: _.assign({}, this.props.flipTransformStyle, this.props.shapeStyle),
                className: this.classSet({
                    [this.props.id]: true,
                    'non-scaling-stroke': !this.props.shouldScaleStroke
                }),
                key: this.props.compProp.displayMode + (strokeWidth ? '_stroke' : '_nostroke'),
                dangerouslySetInnerHTML: {__html: this.props.svgString}
            };
        },

        getSkinProperties() {
            let overrideStyle;
            const strokeWidth = _.get(this.props.shapeStyle, 'strokeWidth');
            const viewBox = _.get(this.props.svgInfo, 'viewBox', '');
            const refData = {
                '': {
                    'data-svg-id': this.props.svgId,
                    'data-svg-type': this.props.svgType,
                    'data-display-mode': this.props.compProp.displayMode,
                    'data-strokewidth': strokeWidth,
                    'data-viewbox': viewBox,
                    'data-preserve-viewbox': this.props.preserveViewBox ? 'preserve' : 'ignore',
                    style: this.props.style
                },
                'link': getLinkAttributes(this.props.link),
                'svg': this.getSvgContent(strokeWidth)
            };

            if (!_.isEmpty(this.props.overrideColorsAsCss)) {
                const styleHtml = getStyleHtml(this.props.overrideColorsAsCss, this.props.styleId, this.props.id);
                overrideStyle = santaComponents.utils.createReactElement('style', {
                    dangerouslySetInnerHTML: {__html: styleHtml}
                });
                refData[''].addChildrenBefore = [overrideStyle];
            }
            return refData;
        }
    };
});
